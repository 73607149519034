import { React, Component } from 'react';
import './Style/forecast.css'
import forecastPng from './Style/img/forecast.png'


class Forecast extends Component {
    componentDidMount() {
        this.props.onChangePage(3);
    }
    render() {
        return (
            <div id='forecast' className='forecast'>
                <div className='forecast__title'>АС «Прогноз»</div>
                <div className='forecast__desc'>Система прогнозирования объёмов нефтепереработки в РФ</div>
                <div className='forecast__img'><img className='miss__png' src={forecastPng}></img></div>
                <h2 className='forecast__desc-title'>Назначение</h2>
                <p className='forecast__desc-text'>Автоматизированная система «Прогноз» предназначена для прогнозирования объёмов нефтепереработки на российских НПЗ.<br />Прогнозирование выполняется на основе уникальной математической модели, представляющей собой задачу линейного программирования. Модель использует фактические показатели работы НПЗ в прошлых периодах, прогноз изменения структуры мощностей и объёма ППН. Возможность наложения дополнительных ограничений и настройки целевой функции позволяет обеспечить наиболее правдоподобные результаты с точки зрения загрузки мощностей и выхода нефтепродуктов.</p>
                <a target='__blanc' href='https://lukoil.ru/' className='lukoil__link'>Разработана по заказу ООО «ИГ «Петромаркет».</a>
                <h2 className='forecast__desc-title'>Решаемые задачи</h2>
                <p className='forecast__desc-text'>В общей постановке целевая задача системы может быть сформулирована следующим образом: необходимо определить объём производства основных нефтепродуктов и загрузку мощностей НПЗ, если известны структура мощностей по процессам и объём сырья для первичной переработки нефти (ППН).</p>
                <p className='forecast__desc-text'>Однако на практике задача сильно усложняется за счёт следующих факторов:</p>
                <ul className='forecast__tasks'>
                    <li className='forecast__task'>могут задаваться дополнительные ограничения: желаемые объёмы производства некоторых нефтепродуктов как на уровне РФ в целом, так и на уровне отдельных НПЗ (диапазоны значений), а также допустимая загрузка тех или иных мощностей (сверху или снизу);</li>
                    <li className='forecast__task'>структура мощностей может включать как действующие, так и новые (прогнозируемые) мощности, которые в модели учитываются отдельно;</li>
                    <li className='forecast__task'>мощности в течение расчётного периода могут работать в нескольких режимах, характеризуемых различными материальными балансами;</li>
                    <li className='forecast__task'>могут использоваться различные критерии оптимальности решения, если допустимых решений множество.</li>
                </ul>
                <h2 className='forecast__desc-title'>Состав базы данных</h2>
                <p className='forecast__desc-text'>Фактические показатели работы НПЗ в прошлых периодах:</p>
                <ul className='forecast__tasks'>
                    <li className='forecast__task'>Объём первичной переработки нефти.</li>
                    <li className='forecast__task'>Объёмы производства (выхода) основных нефтепродуктов.</li>
                    <li className='forecast__task'>Действующие мощности технологических процессов (по установкам).</li>
                    <li className='forecast__task'>Фактическая загрузка технологических процессов (по процессам).</li>
                </ul>
                <p className='forecast__desc-text'>Прогнозные показатели работы НПЗ в будущих периодах:</p>
                <ul className='forecast__tasks'>
                    <li className='forecast__task'>Объём первичной переработки нефти.</li>
                    <li className='forecast__task'>Объёмы производства (выхода) основных нефтепродуктов.</li>
                    <li className='forecast__task'>Прогнозные мощности технологических процессов (по установкам).</li>
                    <li className='forecast__task'>Прогнозная загрузка технологических процессов (по установкам).</li>
                </ul>
                <p className='forecast__desc-text'>Объём ППН и прогнозные мощности являются исходными данными для расчёта (определяются пользователем). Объёмы производства и загрузка процессов являются результатами прогнозного расчёта.</p>
                <p className='forecast__desc-text'>Справочная информация:</p>
                <ul className='forecast__tasks'>
                    <li className='forecast__task'>Классификатор нефтепродуктов.</li>
                    <li className='forecast__task'>Нефтеперерабатывающие заводы.</li>
                    <li className='forecast__task'>Технологические процессы.</li>
                    <li className='forecast__task'>Виды установок.</li>
                    <li className='forecast__task'>Материальные балансы установок.</li>
                </ul>
            </div>
        )
    }
}
export default Forecast;