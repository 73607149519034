import { React, Component } from 'react';
import semaforPng from './Style/img/semafor.png';
import './Style/semafor.css';

class Semafor extends Component {
    componentDidMount() {
        this.props.onChangePage(3);
    }
    render() {
        return (
            <div id='semafor' className='semafor'>
                <div className='semafor__title'>ИАС «Семафор-А» </div>
                <div className='semafor__desc'>Маркетинговая информационно-справочная система</div>
                <div className='semafor__img'><img className='miss__png' src={semaforPng}></img></div>
                <h2 className='semafor__desc-title'>Назначение</h2>
                <p className='semafor__desc-text'>Информационно-аналитическая система «Семафор-А» предназначена для анализа информации о поставках нефтеналивных и нефтехимических грузов железнодорожным транспортом. Система содержит данные об объёмах и динамике внутренних, экспортных и импортных поставок указанных грузов по железной дороге.<br />Система рассчитана на специалистов маркетинговых и сбытовых подразделений нефтяных и нефтехимических компаний, а также аналитиков, чья профессиональная деятельность связана с изучением нефтяного или нефтехимического рынка. Система делает "прозрачными" перемещение по железной дороге нефти, газового конденсата, автобензина, дизельного топлива, авиакеросина, топочного мазута, а также продукции органической и неорганической химии.</p>
                <a href='https://lukoil.ru/' className='lukoil__link'>Разработана по заказу ОАО «ЛУКОЙЛ».</a>
                <h2 className='semafor__desc-title'>Основные решаемые задачи:</h2>
                <ul className='semafor__tasks'>
                    <li className='semafor__task'>Оценка объёмов и динамики поступления нефтяного сырья, нефтепродуктов и продукции нефтехимии на рынки субъектов РФ по основным группам товаров.</li>
                    <li className='semafor__task'>Оценка объёмов и динамики поступления нефтяного сырья, нефтепродуктов и продукции нефтехимии на экспорт по основным группам товаров.</li>
                    <li className='semafor__task'>Оценка состояния и развития конкурентной среды на региональных рынках нефтепродуктов (субъекты РФ, экономические районы, федеральные округа) в заданный период времени.</li>
                </ul>
                <h2 className='semafor__desc-title'>Состав базы данных</h2>
                <p className='semafor__desc-text'>Эксклюзивная база данных (БД) системы содержит информацию о перевозках нефтеналивных грузов с 2003 года и продукции нефтехимии с 2005 года (всего более 5 млн. записей). Помимо реквизитов, используемых в паспортных документах, в БД размещается дополнительная информация, позволяющая пользователям разбивать объёмы поставок продуктов по станциям отправления и назначения, по регионам отгрузки и доставки, по объектам отправки (НПЗ, НХК, перевалочным базам и т.д.), по объектам получения (нефтебазам, аэропортам, промышленным предприятиям и т.п.), по компаниям-производителям.</p>
                <ul className='semafor__tasks'>
                    <li className='semafor__task'>Цены внешних и внутренних рынков на нейть и нефтепродуктов</li>
                    <li className='semafor__task'>Объёмы поставок нефти и нефтепродуктов на различные рынки, объекты и регионы</li>
                    <li className='semafor__task'>Тарифы на траспортировку нефти и нефтепродуктов различными видами транспорта</li>
                </ul>
            </div>
        )
    }
}
export default Semafor;