import { React, Component } from 'react';
import './Style/online.css';
import { HashLink } from 'react-router-hash-link';
import onlinePng from './Style/img/online.png';

class Online extends Component {
    componentDidMount() {
        this.props.onChangePage(3);
    }
    render() {
        return (
            <div id='online' className='online'>
                <div className='online__title'>Онлайновые услуги</div>
                <div className='online__img'><img className='miss__png' src={onlinePng}></img></div>
                <div className='online__desc'>Компания «Сервис-48 информ» предлагает полный спектр услуг по созданию, внедрению и сопровождению программного обеспечения на базе клиент-серверных и WEB-технологий:</div>
                <ul className='online__desc-list'>
                    <li className='online__desc-text'>Предпроектное обследование</li>
                    <li className='online__desc-text'>Моделирование бизнес-процессов</li>
                    <li className='online__desc-text'>Информационное моделирование</li>
                    <li className='online__desc-text'>Системное проектирование и реализация</li>
                    <li className='online__desc-text'>Опытная эксплуатация и внедрение</li>
                    <li className='online__desc-text'>Обучение пользователей</li>
                    <li className='online__desc-text'>Техническая поддержка</li>
                </ul>
                <div className='online__desc'>Используемые технологии и инструменты:</div>
                <ul className='online__desc-list'>
                    <li className='online__desc-text'>Microsoft SQL Server</li>
                    <li className='online__desc-text'>PostgreSQL</li>
                    <li className='online__desc-text'>Embarcadero RAD Studio</li>
                    <li className='online__desc-text'>Developer Express VCL</li>
                    <li className='online__desc-text'>Visual Build Professional</li>
                    <li className='online__desc-text'>Платформа быстрой разработки KERNEL</li>
                </ul>
                <div className='online__desc'>Примеры разработанных систем приведены в разделе <HashLink smooth to='/projects/#top' className='develop__projects-link'>Проекты</HashLink></div>
            </div>
        )
    }
}
export default Online;