import { React, Component } from 'react';
import background from './Style/img/background3.png'
import './Style/service.css';
import { HashLink } from 'react-router-hash-link';
import ColoredArrow from '../components/ColoredArrow';
import img1 from './Style/img/img1.png';
import img2 from './Style/img/img2.png';
import img3 from './Style/img/img3.png';

class Services extends Component {
    componentDidMount() {
        this.props.onChangePage(2);
    }

    render() {
        return (
            <>
                <div className='top_background' style={{ backgroundImage: `url(${background})` }}>
                    <div className="SubHeaderService">
                        <h1 className="service__title">УСЛУГИ</h1>
                        <p className="service__desc">ИСПОЛЬЗУЙТЕ КАЖДУЮ БИЗНЕС-ВОЗМОЖНОСТЬ ПРИ ПОМОЩИ НАШИХ УСЛУГ</p>
                    </div>
                </div>
                <p className='service__desc-text'>Компания «Сервис-48 Информ» предлагает полный спектр услуг по созданию, внедрению и сопровождению программного обеспечения на базе клиент-серверных и WEB-технологий.</p>
                <div className='service__buttons-block'>
                    <ul className='service__list'>
                        <div className='service__elem' style={{ marginRight: `15px` }}>
                            <HashLink className='service__link' smooth to='/services/develop/#top'>
                                <li className='service__item'>
                                    <div className='serv-item__img'>
                                        <img src={img1} alt=""></img>
                                    </div>
                                    <h1 className='serv-item__title'>Разработка заказного ПО</h1>
                                    <p className='serv-item__desc'>Создание, внедрение и сопровождение <br /> информационно-аналитических систем</p>
                                    <div className='arrow'><ColoredArrow enabled={true} /></div>
                                </li>
                            </HashLink>
                        </div>
                        <div className='service__elem' style={{ marginRight: `15px` }}>
                            <HashLink className='service__link' smooth to='/services/webdev/#top'>
                                <li className='service__item'>
                                    <div className='serv-item__img'>
                                        <img src={img2} alt=""></img>
                                    </div>
                                    <h1 className='serv-item__title'>Разработка сайтов</h1>
                                    <p className='serv-item__desc'>Разработка, развертывание <br /> и сопровождение WEB-сайтов <br /> и порталов</p>
                                    <div className='arrow'><ColoredArrow enabled={true} /></div>
                                </li>
                            </HashLink>
                        </div>
                        {/* <div className='service__elem' style={{ marginRight: `15px` }}>
                            <HashLink className='service__link' smooth to='/services/online/#top'>
                                <li className='service__item'>
                                    <div className='serv-item__img'>
                                        <img src={img3} alt=""></img>
                                    </div>
                                    <h1 className='serv-item__title'>Онлайновые услуги</h1>
                                    <p className='serv-item__desc'>Разработка, развертывание <br /> и сопровождение WEB-сайтов <br /> и порталов</p>
                                    <div className='arrow'><ColoredArrow enabled={true} /></div>
                                </li>
                            </HashLink>
                        </div> */}
                    </ul>
                </div>
            </>

        );
    }
}

export default Services;
