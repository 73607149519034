import { React, Component } from 'react';
import './Style/xprice.css';
import xpricePng from './Style/img/xprice.png';


class XPrice extends Component {
    componentDidMount() {
        this.props.onChangePage(3);
    }
    render() {
        return (
            <div id='xprice' className='xprice'>
                <div className='xprice__title'>ПК «X-PRICE»</div>
                <div className='xprice__desc'>Система регистрации ценовых решений</div>
                <div className='xprice__img'><img className='miss__png' src={xpricePng}></img></div>
                <h2 className='xprice__desc-title'>Назначение</h2>
                <p className='xprice__desc-text'>Система предназначена для поддержки процессов регистрации ценовых решений Downstream-блока компании Заказчика, обеспечения их согласования, утверждения и доведения.<br /> Система не является инструментом моделирования или прогнозирования цен в зависимости от различных факторов. Однако в ней реализована развитая бизнес-логика формирования и прохождения сложных ценовых документов, контроля и анализа цен, доведения цен и ценовых документов до заинтересованных лиц и подразделений.</p>
                <a href='https://lukoil.ru/' className='lukoil__link'>Разработана по заказу ОАО «ЛУКОЙЛ».</a>
                <h2 className='xprice__desc-title'>Решаемые задачи</h2>
                <ul className='xprice__tasks'>
                    <li className='xprice__task'>Организация единого хранилища ценовой информации (ценовых показателей и связанных документов) и поддержание его в актуальном состоянии.</li>
                    <li className='xprice__task'>Формирование сложных ценовых документов заданной или настраиваемой структуры.</li>
                    <li className='xprice__task'>Отслеживание состояния документов в процессе их согласования и утверждения.</li>
                    <li className='xprice__task'>Поддержка бизнес-операций изменения сроков действия и отмены отдельных цен и документов в целом.</li>
                    <li className='xprice__task'>Обеспечение логической целостности ценовой информации (контроль установленных бизнес-ограничений).</li>
                    <li className='xprice__task'>Аналитическая обработка ценовых показателей (запросы по условиям, деловая графика, стандартные отчёты).</li>
                    <li className='xprice__task'>Доведение ценовых документов до заинтересованных лиц и подразделений.</li>
                    <li className='xprice__task'>Интеграция с корпоративной системой словарей и справочников (КССС).</li>
                    <li className='xprice__task'>Обмен ценовой информацией с другими системами через корпоративную интеграционную шину.</li>
                </ul>
                <h2 className='xprice__desc-title'><b>Состав базы данных</b>(основные виды целевой информации)</h2>
                <ul className='xprice__tasks'>
                    <li className='xprice__task'>Плановые цены реализации нефти и нефтепродуктов на внутреннем рынке.</li>
                    <li className='xprice__task'>Фактические цены реализации нефти и нефтепродуктов на внутреннем рынке.</li>
                    <li className='xprice__task'>Прогнозные цены на нефть и нефтепродукты.</li>
                    <li className='xprice__task'>Экспортные цены на нефть и нефтепродукты при фактических поставках на экспорт.</li>
                    <li className='xprice__task'>Котировки цен на нефть и нефтепродукты на мировых рынках.</li>
                    <li className='xprice__task'>Налоги, пошлины и сборы, участвующие в формировании цен на нефть и нефтепродукты.</li>
                </ul>
            </div>
        )
    }
}

export default XPrice;