import { React, Component } from 'react';
import './App.css';
import Header from "./header";
import Footer from './footer';
import Main from './1-Main/main';
import Projects from './2-Projects/projects';
import Services from './3-Services/services';
import Clients from './4-Clients/clients';
import Contacts from './5-Contacts/contacts';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Miss from './2-1-Projects/MISS/miss';
import MISS from './2-1-Projects/MISS/miss';
import CMM from './2-1-Projects/CMM/cmm';
import XPrice from './2-1-Projects/XPRICE/xprice';
import Effs from './2-1-Projects/EFFS/effs';
import Forecast from './2-1-Projects/FORECAST/forecast';
import Empas from './2-1-Projects/EMPAS/empas';
import WebDev from './2-1-Projects/ETHERNET/webdev';
import Develop from './3-1-Services/Develop/develop';
import Online from './3-1-Services/OnlineServs/online';
import WDS from './3-1-Services/WebDevelopServ/webdevserv';
import Semafor from './2-1-Projects/SEMAFOR/semafor';
import Dict from './2-1-Projects/DICT/dict';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPageIndex: 0
        }
    }

    onChangePage = (idx) => {
        this.setState({currentPageIndex: idx});
    }

    render() {
        
        return (
            <Router>
                <div className="App">

                    <Header fill={this.state.currentPageIndex <= 2 ? 'white' : '#476070'} currentPageIndex={this.state.currentPageIndex} closeMenu={this.closeMenu}/>

                    <Routes>
                        <Route exact path='/'         element={<Main onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/projects' element={<Projects onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/projects/miss' element={<MISS onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/projects/cmm' element={<CMM onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/projects/xprice' element={<XPrice onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/projects/eff' element={<Effs onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/projects/forecast' element={<Forecast onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/projects/empas' element={<Empas onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/projects/webdev' element={<WebDev onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/projects/semafor' element={<Semafor onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/projects/dict' element={<Dict onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/services' element={<Services onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/services/develop' element={<Develop onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/services/webdev' element={<WDS onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/services/online' element={<Online onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/clients'  element={<Clients onChangePage={this.onChangePage} />}></Route>
                        <Route exact path='/contacts' element={<Contacts onChangePage={this.onChangePage} />}></Route>
                    </Routes>
                    <Footer />
                </div>
            </Router>
        )
    };
}

export default App;
