import { React, Component } from 'react';
import './Style/webdevserv.css';
import wdsPng from './Style/img/wds.png'
import { HashLink } from 'react-router-hash-link';

class WDS extends Component {
    componentDidMount() {
        this.props.onChangePage(3);
    }
    render() {
        return (
            <div id='wds' className='wds'>
                <div className='wds__title'>Разработка сайтов</div>
                <div className='wds__img'><img className='miss__png' src={wdsPng}></img></div>
                <div className='wds__desc-main'>
                    <p className='wds__desc-par'>Наша компания предлагает создание сайта любой сложности. Мы предоставляем услуги по разработке веб-сайтов, принимая во внимание все Ваши потребности и идеи.</p>
                    <p className='wds__desc-par'>Мы можем предложить разработку сайта или веб-приложения, интернет магазина или корпоративного портала, а также создание дизайна и других веб-решений, которые помогут Вам достичь успеха в Вашем бизнесе. </p>
                    <p className='wds__desc-par'>Наша команда предлагает большой выбор возможностей для разработки Вашего веб-сайта, включая мульти-языковую поддержку, адаптивную и мобильную версии, профессиональный дизайн, интеграцию с со сторонними сервисами, аналитику и многое другое. Мы предоставляем безупречные решения, используя самые современные технологии и методы. Мы понимаем, как важно для Вас создать веб-сайт, который будет правильно работать и будет понятен для Ваших посетителей</p>
                    <p className='wds__desc-par'>Наша компания разработчиков имеет большой опыт в разработке сайтов и веб-приложений на различных платформах.</p>
                </div>
                <div className='wds__desc'>Примеры разработанных систем приведены в разделе <HashLink smooth to='/projects/#top' className='develop__projects-link'>Проекты</HashLink></div>
            </div>
        )
    }
}
export default WDS;