import React, { Component } from "react";
import './slider.css'
import ColoredArrow from "./ColoredArrow";

class Ellipse extends Component {
    render() {
        return (
            <svg
                style={{ marginLeft: 16, marginRight: 16, minHeight: 15, cursor: "pointer" }}
                onClick={this.props.onClick}
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle
                    index={this.props.index}
                    cx="6" cy="6" r="5"
                    fill={this.props.color}
                />
            </svg>
        );
    }
}

class Slider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemIndex: this.props.itemIndex
        }
    }

    circleClick = (ev) => {
        this.setState({ itemIndex: Number(ev.target.attributes.index.value) });
        this.props.onCircleClick(Number(ev.target.attributes.index.value));
    }

    arrowClick = (ev) => {
        if (!ev.target.attributes.direction) return;

        let newIndex = ev.target.attributes.direction.value === 'left' ? this.state.itemIndex - 1 : this.state.itemIndex + 1;

        if (newIndex < 0 || newIndex >= this.props.count)
            return;

        this.props.onArrowClick(newIndex);
        this.setState({ itemIndex: newIndex });
    }

    render() {
        let { color, count } = this.props;

        const grayColor = "#C1C7CA";

        return (
            <div className="slider">
                <ColoredArrow
                    color={this.props.color}
                    enabled={this.state.itemIndex === 0 ? false : true}
                    direction="left"
                    onArrowClick={this.arrowClick}>
                </ColoredArrow>

                {Array(...Array(count)).map((v, i) => <Ellipse key={i} index={i} onClick={this.circleClick} color={i === this.state.itemIndex ? color : grayColor}></Ellipse>)}

                <ColoredArrow
                    color={this.props.color}
                    enabled={this.state.itemIndex === count - 1 ? false : true}
                    direction="right"
                    onArrowClick={this.arrowClick}>
                </ColoredArrow>
            </div>
        );
    }
}

export default Slider;