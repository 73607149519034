import { React, Component } from 'react';
import { ZoomControl, Placemark, YMaps, Map } from '@pbe/react-yandex-maps';
import contactImg from './Style/img/contacts.png';
import call from './Style/img/call.png';
import map from './Style/img/map.png';
import './Style/contacts.css';
import 'leaflet/dist/leaflet.css';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from 'leaflet';
// import { MapContainer, TileLayer, Marker, ScaleControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';


class Contacts extends Component {
    componentDidMount() {
        this.props.onChangePage(4);
    }

    render() {
        const coords = [55.851399, 37.575582];

        const defaultState = {
            center: coords,
            zoom: 15,
        };

        return (
            <>
                <div className='contacts-wrap'>
                    <h1 className='contact__title'>Контакты</h1>
                    <div className='contact__img' style={{ backgroundImage: `url(${contactImg})` }}></div>
                </div>
                <ul className='contacts__info'>
                    <li className='contacts__elem'>
                        <img className='contacts__img' src={call} alt=""></img>
                        <ul className='contacts__adds'>
                            <li className='contacts__phone'><a className="contacts__link" href="tel:+74993460920">+7(499) 346-09-20</a></li>
                            <li className='contacts__mail'><a className='contacts__link' href='mailto:service@inform48.ru'>service@inform48.ru</a></li>
                        </ul>
                    </li>
                    <li className='contacts__elem'>
                        <img className='contacts__img' src={map} alt=""></img>
                        <div className='contacts__adds'><a className="contacts__link" href='https://yandex.ru/maps/213/moscow/house/novovladykinskiy_proyezd_8s4/Z04YcwBlQU0FQFtvfXR0cH9qYA==/?ll=37.576683%2C55.850640&z=14.63'>Россия, Москва, 127106 <br /> Нововладыкинский пр-д, д.8, стр.4, офис 409</a></div>
                    </li>
                </ul>
                <div className='contacts__desc'>
                    <h2 className='contacts__desc-title'>КАК ПРОЕХАТЬ НА ОБЩЕСТВЕННОМ ТРАНСПОРТЕ</h2>
                    <div className='contacts__desc-text'> БЦ "Красивый дом" расположен в 7 минутах ходьбы от станции "Окружная". Вам необходимо найти 7 выход со станции МЦД. Ориентиром служит 9 выход из метро. Если вы едете на МЦК (след. ст. Владыкино), то выход из первого вагона или из последнего, если едете в другом направлении (след. ст. Лихоборы)

                    </div>
                </div>
                <div className='contacts__desc'>
                    <h2 className='contacts__desc-title'>КАК ПРОЕХАТЬ НА МАШИНЕ</h2>
                    <div className='contacts__desc-text'>Для более подробной информации рекомендуем перейти по <a className="contacts__link-text" href='https://yandex.ru/maps/213/moscow/house/novovladykinskiy_proyezd_8s4/Z04YcwBlQU0FQFtvfXR0cH9qYA==/?ll=37.576683%2C55.850640&z=14.63'>ССЫЛКЕ</a>.
                    </div>
                </div>
                <div className='contacts__map'>
                    <YMaps>
                        <Map defaultState={defaultState} width="100%" height="100%">
                            <ZoomControl />
                            <Placemark geometry={coords} />
                        </Map>
                    </YMaps>
                </div>
            </>
        );
    }
}

export default Contacts;