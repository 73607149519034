import { React, Component } from 'react';
import './Style/effs.css';
import effsPng from './Style/img/effs.png';
import effImg from './Style/img/eff.jpg';

class Effs extends Component {
    componentDidMount() {
        this.props.onChangePage(3);
    }
    render() {
        return (
            <div id='eff' className='eff-wrap'>
                <div className='eff'>
                    <div className='effs__title'>АС «Эффективность»</div>
                    <div className='effs__desc'>Система расчета эффективности реализации нефти и нефтепродуктов на внешнем и внутреннем рынках</div>
                    <div className='effs__img'><img className='miss__png' src={effsPng}></img></div>
                    <h2 className='effs__desc-title'>Назначение</h2>
                    <p className='effs__desc-text'>Автоматизированная система «Эффективность» предназначена для расчёта и анализа прибыльности экспорта и импорта жидкого углеводородного сырья и нефтепродуктов на различных рынках при различных способах доставки.<br style={{ marginBottom: `10px` }} />Система ориентирована на следующие основные группы пользователей:</p>
                    <ul className='effs__tasks'>
                        <li className='effs__task'>Специалисты маркетинговых и сбытовых подразделений нефтяных компаний.</li>
                        <li className='effs__task'>Трейдеры нефтепродуктового и нефтяного рынков.</li>
                        <li className='effs__task'>Аналитики нефтепродуктового и нефтяного рынков.</li>
                    </ul>
                    <p className='effs__desc-text'>Система также может быть использована специалистами любых иных компаний или организаций, в задачи которых входит мониторинг и прогнозирование состояния российского нефтяного рынка.</p>
                    <a target='__blanc' href='https://petromarket.ru/' className='lukoil__link'>Разработана по заказу ООО «ИГ «Петромаркет».</a>
                    <h2 className='effs__desc-title'>Решаемые задачи</h2>
                    <ul className='effs__tasks'>
                        <li className='effs__task'>Оперативная оценка доходности экспортно-импортных операций.</li>
                        <li className='effs__task'>Прогнозы сравнительной эффективности реализации нефти и нефтепродуктов на заданных рынках в будущих периодах.</li>
                        <li className='effs__task'>Ретроспективный сравнительный анализ эффективности реализации нефти и нефтепродуктов на заданных рынках.</li>
                        <li className='effs__task'>Анализ альтернатив реализации нефти и нефтепродуктов.</li>
                        <li className='effs__task'>Оптимизация экспортных и импортных маршрутов поставки нефти и нефтепродуктов.</li>
                    </ul>
                    <h2 className='effs__desc-title'><b>Состав базы данных</b>(основные виды целевой информации)</h2>
                    <ul className='effs__tasks'>
                        <li className='effs__task'>Цены внешних и внутренних рынков на нейть и нефтепродуктов</li>
                        <li className='effs__task'>Объёмы поставок нефти и нефтепродуктов на различные рынки, объекты и регионы</li>
                        <li className='effs__task'>Тарифы на траспортировку нефти и нефтепродуктов различными видами транспорта</li>
                    </ul>
                    <p className='effs__desc-text'>Основной используемый метод – определение приведённой цены (цены Net-Back) на реализуемый продукт в любом пункте отгрузки/поставки. Данный метод предполагает последовательное вычитание из контрактной цены (цены ликвидного рынка) всех издержек, возникающих в процессе доставки и реализации товара на этом рынке.</p>
                    <img className='effs__imgBlock' src={effImg}></img>
                    <p className='effs__desc-text'>При наличии необходимой информации в базе данных система может быть использована для проведения расчётов применительно к любым:</p>
                    <ul className='effs__tasks'>
                        <li className='effs__task'>местным (внутренним) рынкам: Россия, Казахстан, Украина и т.д.;</li>
                        <li className='effs__task'>торгуемым продуктам: нефть, газовый конденсат, дизельное топливо, мазут, автобензин, нафта и т.п.</li>
                        <li className='effs__task'>источникам котировок цен внутреннего и внешнего рынка: Platt’s, Argus, NYMEX, IPE, Кортес, Петромаркет и т.п.</li>
                        <li className='effs__task'>базисным условиям контрактной цены</li>
                        <li className='effs__task'>формулам цены, основанным на усреднении (взвешивании) котировок плюс/минус дифференциал</li>
                        <li className='effs__task'>маршрутам транспортировки.</li>
                    </ul>
                    <h2 className='effs__desc-title'>Состав базы данных</h2>
                    <p className='effs__desc-text'>Экономическая (стоимостная) информация:</p>
                    <ul className='effs__tasks'>
                        <li className='effs__task'>Котировки цен внешнего и внутреннего рынков.</li>
                        <li className='effs__task'>Котируемые дифференциалы цен внешнего рынка.</li>
                        <li className='effs__task'>Прогнозы цен внешнего и внутреннего рынков.</li>
                        <li className='effs__task'>Налоги и пошлины.</li>
                        <li className='effs__task'>Тарифы на транспортировку различными видами транспорта (трубопроводный, железнодорожный, морской, речной).</li>
                        <li className='effs__task'>Стоимость операций перевалки, слива, налива, паромной переправы и т.п.</li>
                        <li className='effs__task'>Курсы валют.</li>
                    </ul>
                    <p className='effs__desc-text'>Справочная информация:</p>
                    <ul className='effs__tasks'>
                        <li className='effs__task'>Торгуемые продукты и их классификации.</li>
                        <li className='effs__task'>Базисные условия поставок.</li>
                        <li className='effs__task'>Основные формулы расчёта контрактной цены.</li>
                        <li className='effs__task'>Основные маршруты транспортировки и отдельные тарифные участки.</li>
                        <li className='effs__task'>Виды транспорта и транспортные системы.</li>
                        <li className='effs__task'>Страны, регионы, нефтегазоносные провинции, рынки сбыта и т.п.</li>
                        <li className='effs__task'>Объекты инфраструктуры: НПЗ, нефтебазы, транспортные пункты.</li>
                        <li className='effs__task'>Субъекты хозяйственной деятельности: операторы объектов инфраструктуры, экспедиторы, владельцы транспортных средств и т.п.</li>
                    </ul>
                    <h2 className='effs__desc-title'>Функциональные возможности</h2>
                    <ul className='effs__tasks'>
                        <li className='effs__task'>Расчёты приведённой цены продажи (Net-Back) сырья или продукта на любом рынке при поставке по любому маршруту (на основе данных из любых источников).</li>
                        <li className='effs__task'>Расчёты стоимости покупки торгуемого сырья или продукта на любом рынке при доставке по любому маршруту (на основе данных из любых источников).</li>
                        <li className='effs__task'>Сравнительный анализ эффективности различных вариантов продаж (или закупок) сырья или продукта.</li>
                        <li className='effs__task'>Анализ динамики базовых и расчётных экономических показателей (котировок, тарифов, налогов, издержек, приведённых цен, эффективности).</li>
                        <li className='effs__task'>Описание и настройка различных расчётных схем.</li>
                        <li className='effs__task'>Описание новых формул расчёта контрактной цены.</li>
                        <li className='effs__task'>Построение необходимых маршрутов транспортировки продуктов.</li>
                        <li className='effs__task'>Возможность проведения расчётов на основе фактических или прогнозных данных.</li>
                        <li className='effs__task'>Возможность усреднения любых экономических показателей при проведении расчётов.</li>
                        <li className='effs__task'>Оперативная актуализация данных (режим постоянного доступа к обновлениям).</li>
                    </ul>
                </div >
            </div>
        )
    }
}

export default Effs;