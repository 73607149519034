import { React, Component } from 'react';
import webdevPng from './Style/img/webdev.png'
import './Style/webdev.css';

class WebDev extends Component {
    componentDidMount() {
        this.props.onChangePage(3);
    }
    render() {
        return (
            <div id='webdev' className='webdev'>
                <div className='webdev__title'>Интернет-сайты</div>
                <div className='webdev__desc'>Разработка интернет-сайтов любой сложности и назначения</div>
                <div className='webdev__img'><img className='miss__png' src={webdevPng}></img></div>
                <h2 className='webdev__desc-title'>Назначение</h2>
                <p className='webdev__desc-text'>Система предназначена для сбора, хранения и анализа информации, используемой сотрудниками маркетингового подразделения Заказчика при решении целевых задач в рамках бизнес-процессов ценообразования и прогнозирования рынков нефти и нефтепродуктов.<br />Система включает многомодульный программный комплекс, базу данных и файловое хранилище документов. Состав программных модулей постоянно расширяется.</p>
                <a target='__blanc' href='https://lukoil.ru/' className='lukoil__link'>Разработана по заказу ОАО «ЛУКОЙЛ».</a>
                <h2 className='webdev__desc-title'>Решаемые задачи</h2>
                <ul className='webdev__tasks'>
                    <li className='webdev__task'>Информационное взаимодействие с внешними источниками маркетинговой информации (загрузка данных).</li>
                    <li className='webdev__task'>Организация единого хранилища маркетинговых показателей (структурированные данные) и поддержание его в актуальном состоянии.</li>
                    <li className='webdev__task'>Организация хранилища неструктурированных данных (документы различных форматов).</li>
                    <li className='webdev__task'>Интеграция показателей, поступающих из различных источников.</li>
                    <li className='webdev__task'>Предоставление инструментов для аналитической обработки маркетинговых показателей (запросы, кросс-анализ, группировка, сортировки и т.д.).</li>
                    <li className='webdev__task'>Публикация на корпоративном портале хранимых документов и результатов аналитической обработки показателей (витрины данных, отчёты и т.д.).</li>
                </ul>
                <h2 className='webdev__desc-title'><b>Состав базы данных</b>(основные виды целевой информации)</h2>
                <ul className='webdev__tasks'>
                    <li className='webdev__task'>Цены внешних и внутренних рынков на нейть и нефтепродуктов</li>
                    <li className='webdev__task'>Объёмы поставок нефти и нефтепродуктов на различные рынки, объекты и регионы</li>
                    <li className='webdev__task'>Тарифы на траспортировку нефти и нефтепродуктов различными видами транспорта</li>
                </ul>
            </div>
        )
    }
}
export default WebDev;