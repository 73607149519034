import { React, Component } from 'react';
import './Style/dict.css';
import dictPng from './Style/img/dict.png'

class Dict extends Component {
    componentDidMount() {
        this.props.onChangePage(3);
    }
    render() {
        return (
            <div id='dict' className='dict'>
                <div className='dict__title'>Электронные словари</div>
                <div className='dict__desc'>Система регистрации ценовых решений</div>
                <div className='dict__img'><img className='miss__png' src={dictPng}></img></div>
                <h2 className='dict__desc-title'>Назначение</h2>
                <p className='dict__desc-text'>Система предназначена для сбора, хранения и анализа информации, используемой сотрудниками маркетингового подразделения Заказчика при решении целевых задач в рамках бизнес-процессов ценообразования и прогнозирования рынков нефти и нефтепродуктов.<br />
                    <p className='dict__desc-text'>Система включает многомодульный программный комплекс, базу данных и файловое хранилище документов. Состав программных модулей постоянно расширяется.</p></p>
                <a href='https://lukoil.ru/' className='lukoil__link'>Разработана по заказу ОАО «ЛУКОЙЛ».</a>
                <h2 className='dict__desc-title'>Решаемые задачи</h2>
                <ul className='dict__tasks'>
                    <li className='dict__task'>Информационное взаимодействие с внешними источниками маркетинговой информации (загрузка данных).</li>
                    <li className='dict__task'>Организация единого хранилища маркетинговых показателей (структурированные данные) и поддержание его в актуальном состоянии.</li>
                    <li className='dict__task'>Организация хранилища неструктурированных данных (документы различных форматов).</li>
                    <li className='dict__task'>Интеграция показателей, поступающих из различных источников.</li>
                    <li className='dict__task'>Предоставление инструментов для аналитической обработки маркетинговых показателей (запросы, кросс-анализ, группировка, сортировки и т.д.).</li>
                    <li className='dict__task'>Публикация на корпоративном портале хранимых документов и результатов аналитической обработки показателей (витрины данных, отчёты и т.д.).</li>
                </ul>
                <h2 className='dict__desc-title'><b>Состав базы данных</b>(основные виды целевой информации)</h2>
                <ul className='dict__tasks'>
                    <li className='dict__task'>Цены внешних и внутренних рынков на нейть и нефтепродуктов</li>
                    <li className='dict__task'>Объёмы поставок нефти и нефтепродуктов на различные рынки, объекты и регионы</li>
                    <li className='dict__task'>Тарифы на траспортировку нефти и нефтепродуктов различными видами транспорта</li>
                </ul>
            </div>
        )
    }
}
export default Dict;