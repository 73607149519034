import { React, Component } from 'react';
import './Style/empas.css';
import empasPng from './Style/img/empas.png';

class Empas extends Component {
    componentDidMount() {
        this.props.onChangePage(3);
    }
    render() {
        return (
            <div id='empas' className='empas'>
                <div className='empas__title'>ПК «WEB-ЭМПАС»</div>
                <div className='empas__desc'>Электронные маркетинговые паспорта регионов РФ</div>
                <div className='empas__img'><img className='miss__png' src={empasPng}></img></div>
                <h2 className='empas__desc-title'>Назначение</h2>
                <p className='empas__desc-text'>Программный комплекс "Электронные маркетинговые паспорта регионов РФ" предоставляет обобщённую информацию о регионах РФ с точки зрения рынка нефти и нефтепродуктов. Паспорта содержат экономическую статистику, данные о транспортной инфраструктуре, объектах и субъектах нефтяного рынка и другую ежегодно обновляемую информацию.<br />Комплекс реализован в виде WEB-системы, функционирующей в Интернет или Интранет-сети организации.</p>
                <a target='__blanc' href='https://lukoil.ru/' className='lukoil__link'>Разработана по заказу ОАО «ЛУКОЙЛ».</a>
                <h2 className='empas__desc-title'>Основные возможности</h2>
                <ul className='empas__tasks'>
                    <li className='empas__task'>Предоставление разнородной информации (текстовая, табличная, диаграммы) о регионе РФ с точки зрения рынка нефти и нефтепродуктов.</li>
                    <li className='empas__task'>Наличие системы поиска информации в паспортах и разделах.</li>
                    <li className='empas__task'>Возможность сравнения одних и тех же разделов паспортов различных регионов.</li>
                    <li className='empas__task'>Сбор статистической информации об использовании системы: кто, когда и сколько работал с теми или иными материалами.</li>
                    <li className='empas__task'>Разграничение прав доступа групп пользователей к материалам (на уровне регионов и разделов паспортов, а также функций системы).</li>
                    <li className='empas__task'>Доступ к маркетинговым паспортам при помощи WEB-интерфейса через Интранет или Интернет.</li>
                </ul>
            </div>
        )
    }
}
export default Empas;