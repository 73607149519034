import { React, Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import SideBar from "./common/sidebar";
import './header.css';
import styled from 'styled-components';
import { ReactComponent as LogoSVG } from './Style/img/logo33.svg';



let extPageIndex = 0; // это сугубо для StyledLink, так как он находится вне класса

const StyledLink = styled(Link)`
    color: ${() => extPageIndex <= 2 ? '#C1C7CA' : '#476070'};
    margin-left: 95px;
    &:hover { 
        color: ${() => extPageIndex <= 2 ? 'white' : 'black'}
    }
    text-decoration: ${props => props.page === extPageIndex ? "underline" : "none"};
`;


class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPageIndex: 0
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // вернуть true если компонент нуждается в обновлении
        // вернуть false в противном случае

        if (nextProps.currentPageIndex !== nextState.currentPageIndex) {
            this.onLinkClick(nextProps.currentPageIndex);

            return true;
        }

        return false;
    }

    onLinkClick = (idx) => {
        extPageIndex = idx;
        this.setState({ currentPageIndex: idx });
    }

    render() {
        return (
            <div className='Header'>
                <HashLink smooth to="/#top" className='logo'>
                   <LogoSVG fill={this.props.fill} />
                </HashLink>

                <ul className="header-menu">
                    <li className='header-menu__item'><StyledLink to="/" page={0} onClick={() => { this.onLinkClick(0) }}> Главная </StyledLink></li>
                    <li className='header-menu__item'><StyledLink to="/projects" page={1} onClick={() => { this.onLinkClick(1) }}> Проекты </StyledLink></li>
                    <li className='header-menu__item'><StyledLink to="/services" page={2} onClick={() => { this.onLinkClick(2) }}> Услуги </StyledLink></li>
                    <li className='header-menu__item'><StyledLink to="/clients" page={3} onClick={() => { this.onLinkClick(3) }}> Клиенты </StyledLink></li>
                    <li className='header-menu__item'><StyledLink to="/contacts" page={4} onClick={() => { this.onLinkClick(4) }}> Контакты </StyledLink></li>
                </ul>

                <div className='sidebar'>
                    <SideBar width={200} color={this.props.fill} closeMenu={this.props.closeMenu} />
                </div>
            </div>
        );
    };
};

export default Header;