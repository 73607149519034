import { React, Component, Fragment } from 'react';
import './Style/main.css';
import background from './Style/img/background-header-main.jpeg';
import About from './about';
import Clients from './clients';
import ProjBlock from '../components/ProjBlock'
import ServiceBlock from './service';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTextBlock: 0
        };
    };

    componentDidMount() {
        this.props.onChangePage(0);

        setInterval(() => {
            let i = this.state.currentTextBlock;
            if (i >= 2) {
                i = 0
            } else {
                i++
            };
            this.setState({ currentTextBlock: i });
        }, 5000);
    };

    topTextArr = [
        {
            "title": "Наши решения - ваши новые возможности!",
            "desc": "Используйте каждую бизнес-возможность при помощи наших продуктов",
        },
        {
            "title": "Компания Сервис-48 Информ быстрорастущая организация!",
            "desc": "Держим руку на пульсе, используя актуальные кейсы и софт",
        },
        {
            "title": "Возникли вопросы? Мы всегда на связи!",
            "desc": "Подробная информация в разделе Контакты :)",
        }
    ];

    changeTextBlock = (index) => {
        this.setState({ currentTextBlock: index })
    }

    getRightTextBlock = () => {
        switch (this.state.currentTextBlock) {
            case 0:
                return (
                    <div className="SubHeaderAbout">
                        <h1 key="a1" id="main__title" className="main__title">{this.topTextArr[0]['title']}</h1>
                        <p ley="b1" id="main__desc" className="main__desc">{this.topTextArr[0]['desc']}</p>
                    </div>
                );
            case 1:
                return (
                    <div className="SubHeaderAbout">
                        <h1 key="a2" id="main__title" className="main__title">{this.topTextArr[1]['title']}</h1>
                        <p key="b2" id="main__desc" className="main__desc">{this.topTextArr[1]['desc']}</p>
                    </div>
                );
            case 2:
                return (
                    <div className="SubHeaderAbout">
                        <h1 key="a3" id="main__title" className="main__title">{this.topTextArr[2]['title']}</h1>
                        <p key="b3" id="main__desc" className="main__desc">{this.topTextArr[2]['desc']}</p>
                    </div>
                );
            default: return <div></div>;
        }
    }

    render() {
        return (
            <>
            <div className='top_background' style={{backgroundImage: `url(${background})`}}>
                <div>{this.getRightTextBlock()}</div>
            </div>
            <About itemIndex={0}/>
            <Clients />
            <ProjBlock />
            <ServiceBlock />
            </>
        );
    }
}

export default Main;