import { React, Component } from 'react';
import './Style/clients.css';
import lukoil from './Style/img/lukoil.png';
import petromarket from './Style/img/petromarket.png';
import CDU from './Style/img/CDU.png';

class Clients extends Component {
    render() {
        return (
            <div className='clients'>
                <div className='clients__wrap'>
                    <h1 className='clients__title'>Наши клиенты</h1>
                    <ul className='clients__imgs'>
                        <li className='clients__img'><a target='__blanc' href='https://lukoil.ru/'><img className='clients__img-item1' src={lukoil}></img></a></li>
                        <li className='clients__img'><a target='__blanc' href='https://petromarket.ru/'><img className='clients__img-item2' src={petromarket}></img></a></li>
                        <li className='clients__img'><a target='__blanc' href='https://www.cdu.ru/'><img className='clients__img-item3' src={CDU}></img></a></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Clients;
