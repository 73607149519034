import { React, Component } from "react";
import './ProjBlock.css';
import { Link } from 'react-router-dom';
import ColoredArrow from "./ColoredArrow";
import { HashLink } from 'react-router-hash-link';
import styled from "styled-components";

const StyledHashLink = styled(HashLink)`
    text-decoration: none;
    width: 100%;
`;

class ProjBlock extends Component {
    render() {
        return (
            <div className="projects">
                <div className="project-title">Проекты</div>
                <ul className="project__list">
                    <li className="project__item">
                        <StyledHashLink smooth to="/projects/miss/#miss">
                            <div className="item__wrap">
                                <h2 className="item__title">ПК «МИСС»</h2>
                                <p className="item__desc">Маркетинговая <br />информационно-справочная<br /> система</p>
                                <div className="button__next-item"><ColoredArrow enabled={true} /></div>
                            </div>
                        </StyledHashLink>
                    </li>
                    <li className="project__item">
                        <StyledHashLink smooth to="/projects/cmm/#cmm">
                            <div className="item__wrap">
                                <h2 className="item__title">ПК «CMM»</h2>
                                <p className="item__desc">Система <br />многоуровневого <br />мониторинга</p>
                                <div className="button__next-item"><ColoredArrow enabled={true} /></div>
                            </div>
                        </StyledHashLink>
                    </li>
                    <li className="project__item">
                        <StyledHashLink smooth to="/projects/xprice/#xprice">
                            <div className="item__wrap">
                                <h2 className="item__title">ПК «X-PRICE»</h2>
                                <p className="item__desc">Система <br />регистрации <br /> ценовых решений</p>
                                <div className="button__next-item"><ColoredArrow enabled={true} /></div>
                            </div>
                        </StyledHashLink>
                    </li>
                    <li className="project__item">
                        <StyledHashLink smooth to="/projects/eff/#eff">
                            <div className="item__wrap">
                                <h2 className="item__title">АС «Эффективность»</h2>
                                <p className="item__desc">Система расчета эффективности <br />реализации
                                    нефти <br /> и нефтепродуктов <br />на внешнем и внутреннем рынках</p>
                                <div className="button__next-item"><ColoredArrow enabled={true} /></div>
                            </div>
                        </StyledHashLink>
                    </li>
                    <li className="project__item">
                        <StyledHashLink smooth to="/projects/forecast/#forecast">
                            <div className="item__wrap">
                                <h2 className="item__title">ПК «Прогноз»</h2>
                                <p className="item__desc">Система прогнозирования <br />объёмов нефтепереработки <br />в РФ</p>
                                <div className="button__next-item"><ColoredArrow enabled={true} /></div>
                            </div>
                        </StyledHashLink>
                    </li>
                    <li className="project__item">
                        <StyledHashLink smooth to="/projects/empas/#empas">
                            <div className="item__wrap">
                                <h2 className="item__title">ПК «WEB-ЭМПАС»</h2>
                                <p className="item__desc">Электронные <br />маркетинговые <br />паспорта регионов РФ</p>
                                <div className="button__next-item"><ColoredArrow enabled={true} /></div>
                            </div>
                        </StyledHashLink>
                    </li>
                    {/* <li className="project__item">
                        <StyledHashLink smooth to="/projects/webdev/#webdev">
                            <div className="item__wrap">
                                <h2 className="item__title">Интернет-сайты</h2>
                                <p className="item__desc">Разработка интернет-сайтов <br />любой сложности <br />и назначения</p>
                                <div className="button__next-item"><ColoredArrow enabled={true} /></div>
                            </div>
                        </StyledHashLink>
                    </li> */}
                    <li className="project__item">
                        <StyledHashLink smooth to="/projects/semafor/#semafor">
                            <div className="item__wrap">
                                <h2 className="item__title">ИАС «Семафор-А»</h2>
                                <p className="item__desc">Разработка интернет-сайтов <br />любой сложности <br />и назначения</p>
                                <div className="button__next-item"><ColoredArrow enabled={true} /></div>
                            </div>
                        </StyledHashLink>
                    </li>
                    <li className="project__item">
                        <StyledHashLink smooth to="/projects/dict/#dict">
                            <div className="item__wrap">
                                <h2 className="item__title">Электронные словари</h2>
                                <p className="item__desc">Разработка интернет-сайтов <br />любой сложности <br />и назначения</p>
                                <div className="button__next-item"><ColoredArrow enabled={true} /></div>
                            </div>
                        </StyledHashLink>
                    </li>
                </ul>
            </div>
        )
    }
}

export default ProjBlock;