import { React, Component } from 'react';
import './Style/cmm.css';
import cmmPng from './Style/img/cmm.png';

class CMM extends Component {
    componentDidMount() {
        this.props.onChangePage(3);
    }
    render() {
        return (
            <div  id='cmm' className='cmm'>
                <div className='cmm__title'>ПК «CMM»</div>
                <div className='cmm__desc'>Система многоуровневого мониторинга</div>
                <div className='cmm__img'><img className='miss__png' src={cmmPng}></img></div>
                <h2 className='cmm__desc-title'>Назначение</h2>
                <p className='cmm__desc-text'>Система предназначена для автоматизации сбора и обработки маркетинговой информации о состоянии рынков нефти и нефтепродуктов в рамках информационной поддержки процессов принятия оперативных маркетинговых решений.<br />Система строится по принципу распределённой иерархической сети: информация из региональных узлов передаётся в промежуточные и далее – в центральный узел. Количество уровней иерархии неограничено. В каждом узле функционирует типовой программный комплекс и локальная база данных. Передача информации осуществляется посредством электронной почты.<br /> Поддерживается также терминальный режим работы, при котором программный комплекс и базы данных всех или некоторых узлов функционируют на удалённом сервере. Передача информации между такими узлами производится на сервере собственными средствами системы. На практике часть узлов работает в терминальном режиме, а часть – в автономном.</p>
                <p className='cmm__desc-text'><a target='__blanc' href='https://lukoil.ru/' className='lukoil__link'>Разработана по заказу ОАО «ЛУКОЙЛ».</a>. Функционирует более чем в 50 российских и зарубежных узлах компании. Для зарубежных узлов создана специальная англоязычная версия программного комплекса.</p>
                <h2 className='cmm__desc-title'>Решаемые задачи</h2>
                <ul className='cmm__tasks'>
                    <li className='cmm__task'>Сбор и хранение информации в локальной базе данных (ручной ввод).</li>
                    <li className='cmm__task'>Приём и распаковка данных, переданных из нижестоящих узлов.</li>
                    <li className='cmm__task'>Упаковка и передача данных в вышестоящий узел.</li>
                    <li className='cmm__task'>Аналитическая обработка данных (запросы по условиям, деловая графика, стандартные отчёты).</li>
                </ul>
                <h2 className='cmm__desc-title'><b>Состав базы данных</b>(основные виды целевой информации)</h2>
                <ul className='cmm__tasks'>
                    <li className='cmm__task'>Ценовой мониторинг: отпускные цены НПЗ, цены крупнооптовые, цены мелкооптовые, розничные цены в регионе.</li>
                    <li className='cmm__task'>Мониторинг товарных потоков: оптовые поставки в регион, суточная розничная реализация.</li>
                    <li className='cmm__task'>Дополнительная информация: текстовые сообщения о событиях и новостях на рынке.</li>
                </ul>
            </div>
        )
    }
}

export default CMM;