import { React, Component } from 'react';
import './Style/develop.css';
import developPng from './Style/img/develop.png';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

class Develop extends Component {
    componentDidMount() {
        this.props.onChangePage(3);
    }
    render() {
        return (
            <div id='develop' className='develop'>
                <div className='develop__title'>Разработка ПО</div>
                <div className='develop__img'><img className='miss__png' src={developPng}></img></div>
                <div className='develop__desc'>Компания «Сервис-48 информ» предлагает полный спектр услуг по созданию, внедрению и сопровождению программного обеспечения на базе клиент-серверных и WEB-технологий:</div>
                <ul className='develop__desc-list'>
                    <li className='develop__desc-text'>Предпроектное обследование</li>
                    <li className='develop__desc-text'>Моделирование бизнес-процессов</li>
                    <li className='develop__desc-text'>Информационное моделирование</li>
                    <li className='develop__desc-text'>Системное проектирование и реализация</li>
                    <li className='develop__desc-text'>Опытная эксплуатация и внедрение</li>
                    <li className='develop__desc-text'>Обучение пользователей</li>
                    <li className='develop__desc-text'>Техническая поддержка</li>
                </ul>
                <div className='develop__desc'>Используемые технологии и инструменты:</div>
                <ul className='develop__desc-list'>
                    <li className='develop__desc-text'>Microsoft SQL Server</li>
                    <li className='develop__desc-text'>PostgreSQL</li>
                    <li className='develop__desc-text'>Embarcadero RAD Studio</li>
                    <li className='develop__desc-text'>Developer Express VCL</li>
                    <li className='develop__desc-text'>Visual Build Professional</li>
                    <li className='develop__desc-text'>Платформа быстрой разработки KERNEL</li>
                </ul>
                <div className='develop__desc'>Примеры разработанных систем приведены в разделе <HashLink smooth to='/projects/#top' className='develop__projects-link'>Проекты</HashLink></div>
            </div>
        )
    }
}
export default Develop;