import React, { Component } from "react";

class ColoredArrow extends Component {

    render() {
        let { color } = this.props;
        if (!color) color = "#FEFEFE";


        const grayColor = "#C1C7CA";

        return (
            <div 
                style={{
                    cursor: this.props.enabled ? "pointer" : "default",
                    marginRight: this.props.direction === 'left' ? 10 : 0,
                    marginLeft: this.props.direction === 'right' ? 10 : 0,
                    transform: this.props.direction === 'left' ? "rotate(180deg)" : "rotate(0deg)"
                }}
                direction={this.props.direction}
                onClick={this.props.onArrowClick}>

                <svg
                    direction={this.props.direction}
                    style={{ maxHeight: 16 }}
                    width={25}
                    height={14}
                    viewBox="0 0 25 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        direction={this.props.direction}
                        d="M18.6858 0.0083313L16.9923 1.70129L20.6163 5.32577H10.7627V7.72161H20.616L16.9923 11.3453L18.6858 13.0387L25.2018 6.52385L18.6858 0.0083313ZM4.944 7.72161H8.72016V5.32545H4.944V7.72161ZM0.732635 7.72161H2.72224V5.32545H0.732635V7.72161Z"
                        fill={this.props.enabled ? color : grayColor}
                    />
                </svg>
            </div>
        );
    }
}

export default ColoredArrow;
