import { React, Component } from 'react';
import clientsImg from './Style/img/clients.jpeg'
import './Style/clients.css';
import lukoil from '../1-Main/Style/img/lukoil.png';
import CDU from '../1-Main/Style/img/CDU.png';
import petromarket from '../1-Main/Style/img/petromarket.png'

class Clients extends Component {
    componentDidMount() {
        this.props.onChangePage(3);
    }

    render() {
        return (
            <div className='clients-wrap'>
                <h1 className='client__title'>Клиенты</h1>
                <div className='client__img' style={{backgroundImage: `url(${clientsImg})`}}></div>
                <ul className='clients__imgs clients-page__imgs'>
                    <li className='clients__img client-page__img'><a target='__blanc' href='https://lukoil.ru/'><img className='clients__img-item1' src={lukoil}></img></a></li>
                    <li className='clients__img client-page__img'><a target='__blanc' href='https://petromarket.ru/'><img className='clients__img-item2' src={petromarket}></img></a></li>
                    <li className='clients__img client-page__img'><a target='__blanc' href='https://www.cdu.ru/'><img className='clients__img-item3' src={CDU}></img></a></li>
                </ul>
            </div>
            
        );
    }
}

export default Clients;