import { React, Component } from 'react';
import './footer.css';
import map from './Style/img/map.png';
import call from './Style/img/call.png';
import { HashLink } from 'react-router-hash-link';
import { ReactComponent as LogoSvg } from './Style/img/logo33.svg';
//import telegram from './1-About/Style/img/telegram.png';
import mail from './Style/img/mail.png';

class Footer extends Component {

    getCurYear = () => {
        let today = new Date();
        return today.getFullYear().toString();
    }
    
    render() {
        return (
            <div className='footer'>
                <div className='footer__wrap'>
                    <HashLink smooth to="/#top"><LogoSvg fill={'white'}></LogoSvg></HashLink>
                    <ul className='footer__list'> Проекты
                        <li className='footer__item'><HashLink smooth to="/projects/miss/#miss" className='footer__link'>ПК «МИСС»</HashLink></li>
                        <li className='footer__item'><HashLink smooth to="/projects/cmm/#cmm" className='footer__link'>ПК «СММ»</HashLink></li>
                        <li className='footer__item'><HashLink smooth to="/projects/xprice/#xprice" className='footer__link'>ПК «X-PRICE»</HashLink></li>
                        <li className='footer__item'><HashLink smooth to="/projects/eff/#eff" className='footer__link'>АС «Эффективность»</HashLink></li>
                        <li className='footer__item'><HashLink smooth to="/projects/forecast/#forecast" className='footer__link'>АС «Прогноз»</HashLink></li>
                        <li className='footer__item'><HashLink smooth to="/projects/empas/#empas" className='footer__link'>ПК «WEB-ЭМПАС»</HashLink></li>
                        <li className='footer__item'><HashLink smooth to="/projects/semafor/#semafor" className='footer__link'>ИАС «Семафор-А»</HashLink></li>
                        {/* <li className='footer__item'><HashLink smooth to="/projects/webdev/#webdev" className='footer__link'>Интернет-сайты</HashLink></li> */}
                        <li className='footer__item'><HashLink smooth to="/projects/dict/#dict" className='footer__link'>Электронные словари</HashLink></li>
                    </ul>
                    <ul className='footer__list'> Услуги
                        <li className='footer__item'><HashLink smooth to="/services/develop/#top" className='footer__link'>Разработка ПО</HashLink></li>
                        <li className='footer__item'><HashLink smooth to="/services/webdev/#top" className='footer__link'>Разработка сайтов</HashLink></li>
                        {/* <li className='footer__item'><HashLink smooth to="/services/online/#top" className='footer__link'>Онлайновые услуги</HashLink></li> */}
                    </ul>
                </div>
                <div className='breadcrumbs'>
                    <div className='allRights'>© 2001-{this.getCurYear()} ООО «Сервис-48 Информ». Все права защищены</div>
                    <address className='footer__address'>
                        <div className='address__item'>
                            <img className='address__img' src={map} alt="addr"></img>
                            <div className='address__elem'><a className="address__link" href='https://yandex.ru/maps/213/moscow/house/novovladykinskiy_proyezd_8s4/Z04YcwBlQU0FQFtvfXR0cH9qYA==/?ll=37.576683%2C55.850640&z=14.63'>Россия, Москва, 127106 <br /> Нововладыкинский пр-д, д.8, стр.4, офис 409</a></div>
                        </div>
                        <div className='address__item'>
                            <img className='address__img' src={call} alt="call"></img>
                            <div className='address__elem'><a className="address__link" href="tel:+74993460920">+7(499) 346-09-20</a></div>
                        </div>
                        <div className='address__item'>
                            <img className='address__img' src={mail} alt="mail"></img>
                            <div className='address__elem'><a className='address__link' href='mailto:service@inform48.ru'>service@inform48.ru</a></div>
                        </div>
                    </address>
                </div>
            </div>
        )
    }
}

export default Footer;