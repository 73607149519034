import { React, Component } from 'react';
import { slide as Menu } from "react-burger-menu";
import { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
import "./sidebar.css";

const StyledMenu = createGlobalStyle`
    .bm-burger-bars {
        background: ${props => props.color}; 
    }
    .bm-burger-button {
        top: 23px;
        right: 23px;
    }
    .bm-overlay {
        width: 300px;
    }
    .bm-menu-wrap {
        top: 0px;
    }
`;

class SideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        }
    }

    closeMenu = () => {
        this.setState({isOpen: false});
    }

    handleIsOpen = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        return (
            <>
                <StyledMenu color={this.props.color} />

                <Menu right={true} width={this.props.width} isOpen={this.state.isOpen} onOpen={this.handleIsOpen} onClose={this.handleIsOpen}>
                    <Link to="/" page={0} onClick={this.closeMenu}> Главная  </Link>
                    <Link to="/projects" page={1} onClick={this.closeMenu}> Проекты  </Link>
                    <Link to="/services" page={2} onClick={this.closeMenu}> Услуги   </Link>
                    <Link to="/clients" page={3} onClick={this.closeMenu}> Клиенты  </Link>
                    <Link to="/contacts" page={4} onClick={this.closeMenu}> Контакты </Link>
                </Menu>
            </>
        );
    }
};

export default SideBar;