import { React, Component } from 'react';
import background from './Style/img/projects.jpeg';
import ProjBlock from '../components/ProjBlock';
import './Style/projects.css';

class Projects extends Component {
    componentDidMount() {
        this.props.onChangePage(1);
    }

    render() {
        return (
            <>
                <div className='top_background' style={{ backgroundImage: `url(${background})` }}>
                    <div className="SubHeaderProjects">
                        <h1 className="project__title">НАШИ ПРОЕКТЫ</h1>
                        <p className="project__desc">ИСПОЛЬЗУЙТЕ КАЖДУЮ БИЗНЕС-ВОЗМОЖНОСТЬ ПРИ ПОМОЩИ НАШИХ ПРОДУКТОВ</p>
                    </div>
                </div>
                <p className='project__desc-text'>Внедрение современных технологий управления проектами позволяет компании вести эффективную разработку, внедрение и сопровождение программных комплексов, обеспечивать их высокое качество и надёжность в соответствии с российскими и международными стандартами.</p>
                <ProjBlock />
            </>
        );
    }
}

export default Projects;