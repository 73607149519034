import { React, Component } from "react";
import './Style/about.css';
//import { CSSTransition } from "react-transition-group";
import Slider from '../components/slider'
import about1 from './Style/img/about1.jpeg'
import about2 from './Style/img/about2.jpeg'
import about3 from './Style/img/about3.jpeg'


class About extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemIndex: this.props.itemIndex
        }
    }

    componentDidMount() {
        if (this.props.changeBackground)
            this.props.changeBackground(0);
    }


    onCircleClick = (index) => {
        // console.log(index);
        this.setState({ itemIndex: index });
    }

    onArrowClick = (index) => {
        // console.log(index);
        this.setState({ itemIndex: index });
    }

    render() {

        const newAboutBlock = () => {

            if (this.state.itemIndex === 0) {
                return (
                    <div id="about__desc" className="about__desc0" style={{ display: 'block' }}>
                        <h2 className="about__desc-title">О НАС</h2>
                        <p className="about__desc-text">Компания «Сервис-48 Информ» создана в 2001 году. Основные виды деятельности – разработка программного обеспечения и информационно-технологические услуги.</p>
                        <p className="about__desc-text">Основа компании – высококвалифицированные специалисты, имеющие многолетний опыт работы в области информационных технологий.</p>
                        <p className="about__desc-text">«Сервис-48 Информ» – гибкая и динамичная организация, наш основной принцип – «не останавливаться на достигнутом». Сотрудники готовы быстро реагировать на перемены в работе заказчиков и партнёров, предлагать им новые современные решения.</p>
                    </div>
                )
            }
            else if (this.state.itemIndex === 1) {
                return (
                    <div id="about__desc" className="about__desc1" style={{ display: 'block' }}>
                        <h2 className="about__desc-title">СТРАТЕГИЧЕСКИЕ НАПРАВЛЕНИЯ В РАБОТЕ</h2>
                        <ul className="about__desc-list">
                            <li className="about__desc-item">Разработка информационно-аналитических систем в сфере маркетинга.</li>
                            <li className="about__desc-item">Комплексные решения по автоматизации бизнес-процессов.</li>
                            <li className="about__desc-item">Создание интернет-сайтов и порталов различной степени сложности.</li>
                            <li className="about__desc-item">Сервисное обслуживание ИТ-инфраструктуры организаций.</li>
                        </ul>
                        <p className="about__desc-text">Внедрение современных технологий управления проектами позволяет компании вести эффективную разработку, внедрение и сопровождение программных комплексов, обеспечивать их высокое качество и надёжность в соответствии с российскими и международными стандартами.</p>
                    </div>
                )
            }
            else if (this.state.itemIndex === 2) {
                return (
                    <div id="about__desc" className="about__desc2" style={{ display: 'block' }}>
                        <h2 className="about__desc-title">ПРЕИМУЩЕСТВА РАЗРАБАТЫВАЕМЫХ ПРОДУКТОВ</h2>
                        <ul className="about__desc-list">
                            <li className="about__desc-item">Практичность, ориентация на решение конкретных задач, стоящих перед пользователями.</li>
                            <li className="about__desc-item">Масштабируемость и расширяемость, возможность изменения конфигурации.</li>
                            <li className="about__desc-item">Сочетание новейших «промышленных» решений и «фирменных» технологий и инструментов.</li>
                        </ul>
                        <p className="about__desc-text">Продукты и решения «Сервис-48 информ» успешно эксплуатируются в крупнейших компаниях федерального масштаба, таких как ОАО «ЛУКОЙЛ», АК «Транснефть», ОАО «СИБУР» и др.</p>
                    </div>
                )
            }
        }


        return (

            <div className="about">
                <h1 className="about__title">О компании</h1>
                <div className="about__wrap">
                    <div className="imgs_wrap">
                        <div className="about__imgs">
                            <img className="about__img img1" src={about1} alt=""></img>
                            <img className="about__img img2" src={about2} alt=""></img>
                            <img className="about__img img3" src={about3} alt=""></img>
                        </div>
                    </div>
                    <div className="about__desc">{newAboutBlock()}
                        <Slider className="slider"
                            color='#01395A'
                            count={3}
                            itemIndex={this.state.itemIndex}
                            onCircleClick={this.onCircleClick}
                            onArrowClick={this.onArrowClick}>
                        </Slider>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;
