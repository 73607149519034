import { React, Component } from "react";
import { HashLink } from 'react-router-hash-link';
import './Style/service.css'
import ColoredArrow from "../components/ColoredArrow";

class ServiceBlock extends Component {
    render() {
        return (
            <div className="serv">
                <div className="serv__wrap">
                    <div className="main-content__wrap">
                        <h1 className="serv__title">Услуги</h1>
                        <h1 className="serv__header">Разработка ПО</h1>
                        <p className="serv__desc">Компания «Сервис-48 Информ» предлагает полный спектр услуг по<br /> созданию, внедрению и сопровождению программного обеспечения на базе<br /> клиент-серверных и WEB-технологий</p>
                        <HashLink smooth to='/services/#top'>
                            <div className="button__info">Подробнее <div className="arrow"><ColoredArrow color={'#01395A'} enabled={true} /></div></div>
                        </HashLink>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServiceBlock;